import { styleConfig } from '../config';

export const { gridSizes } = styleConfig;

export const themeToGridSize = {
  NORD: 'gddsFullGrid',
  KOLO: 'gddsFullGrid',
  TWYFORD: 'gddsFullGrid',
  GDDS: 'full',
  NORD_CAROUSEL: 'gddsCarouselFull',
  GDDS_CAROUSEL: 'carouselFull',
};

export function buildSize(size: number, offset = 0) {
  return `${size + offset}rem`;
}
