import { isClient } from './environment';
import { emptyNumberFallback } from './is-empty';

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 600;

export function handlePopup(link: Link, windowId) {
  if (!isClient()) return;

  let featureString = '';
  const width = emptyNumberFallback(link.popup?.width, DEFAULT_WIDTH);
  const height = emptyNumberFallback(link.popup?.height, DEFAULT_HEIGHT);

  const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;
  featureString += `,width=${width},left=${left},`;

  const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  featureString += `height=${height},top=${top}`;

  window.open(link?.target, windowId, featureString);
}
