import { currentLanguageSelector } from './languageSelectors';
import { isPreviewSelector as isPreview } from './environmentSelectors';

export const translationKeysSelector = (state: AppState) => {
  const currentLanguage = currentLanguageSelector(state);
  if (!currentLanguage) return undefined;

  const translations = state.translations[currentLanguage];

  if (!translations) return undefined;

  return translations.keys || undefined;
};

export const translationKeysWithPreviewIdSelector = (state) => {
  const currentLanguage = currentLanguageSelector(state);

  if (!currentLanguage) return undefined;

  const translations = state.translations[currentLanguage];

  if (!currentLanguage || !translations) return undefined;

  return translations.keysWithPreviewId || undefined;
};

export const translationsSelector = (state: AppState) => {
  const currentLanguage = currentLanguageSelector(state);

  if (!currentLanguage) return false;

  const translations = state.translations[currentLanguage];

  if (!translations) return false;

  return translations;
};

export const translationByKeySelector = (state: AppState, key: string): string => {
  const translationsKeys = translationKeysSelector(state);
  const fallback = (isPreview(state) && key) || '';

  if (!translationsKeys) return fallback;

  const translation = translationsKeys[key];

  return translation ?? fallback;
};
