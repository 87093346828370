// utils
import { useIsPreview } from '../../utils/hooks/useIsPreview';
import { isEmpty } from '../../utils/is-empty';

interface EditWrapperProps {
  editType?: string;
  previewId?: string;
  as?: string;
}

export function EditWrapper({
  editType,
  previewId,
  children,
  as: RenderAs = 'div',
  ...props
}: Readonly<React.PropsWithChildren<EditWrapperProps>>) {
  const isPreview = useIsPreview();
  if (!isPreview || isEmpty(previewId)) {
    return children;
  }

  // filter non-allowed parameters to avoid react warnings
  const passProps = Object.fromEntries(
    Object.entries(props).filter(
      ([, val]) => typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean',
    ),
  );

  return (
    <RenderAs
      {...passProps}
      className="c-tpp-area"
      data-preview-id={previewId}
      data-edit-type={editType}
    >
      {children}
    </RenderAs>
  );
}
