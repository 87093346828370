import React, { HTMLProps, PropsWithChildren } from 'react';

// utils
import { classNameBuilder } from '../../utils/classNameBuilder';
import { useIsPreview } from '../../utils/hooks/useIsPreview';
import { useCurrentLanguage } from '../../utils/hooks/useCurrentLanguage';

import { TPP_TYPE_TRANSLATION } from './tppConstants';

export function TranslationEditWrapper({
  children,
  hasError,
  translationId,
  ...spanProps
}: PropsWithChildren<HTMLProps<HTMLSpanElement> & { hasError?: boolean; translationId?: string }>) {
  let errorClass = '';
  const isPreview = useIsPreview();
  const language = useCurrentLanguage();
  if (!isPreview) {
    return <>children</>;
  }

  if (hasError === true) errorClass = 'c-tpp-area__translation-error';

  return (
    <span
      {...spanProps}
      className={classNameBuilder(`c-tpp-area ${errorClass}`, spanProps.className)}
      data-preview-id={`custom:translationKey:${language}:${translationId}`}
      data-edit-type={TPP_TYPE_TRANSLATION}
    >
      {children}
    </span>
  );
}
