import { THEME_NAMES } from '../../themes';
import { useKolo } from './use-kolo';
import { useTwyford } from './use-twyford';
import { useThemeName } from './use-theme';

export function useNord(): boolean {
  const theme = useThemeName();
  const isKolo = useKolo();
  const isTwyford = useTwyford();
  return theme === THEME_NAMES.NORD || isKolo || isTwyford;
}
