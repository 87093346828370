import { classNameBuilder } from 'utils/classNameBuilder';

const formatMapping = {
  '16_9': 'sixteen-nine',
  '16_7': 'sixteen-seven',
  '16_6': 'sixteen-six',
  '4_3': 'four-three',
  '1_1': 'one-one',
  '3_4': 'three-four',
  '8_11': 'eight-eleven',
  '3_2': 'three-two',
  '2_1': 'two-one',
};

export type AspectRatioType = keyof typeof formatMapping;

type AspectRatioProps = {
  aspectRatio?: string;
};

export function AspectRatio({ aspectRatio, children }: React.PropsWithChildren<AspectRatioProps>) {
  return (
    <div
      className={classNameBuilder('image--aspect-ratio', aspectRatio && formatMapping[aspectRatio])}
    >
      <div className="image-content">{children}</div>
    </div>
  );
}
