import { Button } from '@geberit/gdds';

import styles from './fullscreen-iframe.module.scss';

type Props = {
  onClose: () => void;
  src: string;
};

export default function FullscreenIframe({ src, onClose }: Readonly<Props>) {
  return (
    <div className={styles.fullScreenIframe}>
      <iframe src={src} width="100%" height="100%" />
      <div className={styles.closeButton}>
        <Button stylingType="flat" isIcon symbol="Close" onClick={onClose} />
      </div>
    </div>
  );
}
