import { PropsWithChildren, useCallback } from 'react';
import { Link, LinkProps } from '@geberit/gdds';

// Utils
import { ContentProps } from './ContentProps';
import { phoneLink } from './trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useGdds } from 'utils/hooks/use-gdds';

function renderLinkContent(children, content) {
  if (children !== null) {
    return children;
  }

  return content.text;
}

export interface PhoneLinkProps {
  content: ContentProps;
  className?: string;
  tracking?: (e: any) => void;
  hasArrow?: boolean;
  alignByContent?: LinkProps['alignByContent'];
  stylingType?: LinkProps['stylingType'];
  standardFontSize?: boolean;
}

export function PhoneLink({
  content,
  children,
  className = '',
  tracking,
  stylingType,
  hasArrow,
  alignByContent,
  standardFontSize = true,
}: PropsWithChildren<PhoneLinkProps>) {
  const isGdds = useGdds();
  const track = useTracking();
  const handleTracking = useCallback((e) => {
    if (!tracking) {
      track.trackEvent(phoneLink(e.target.href));
    } else {
      tracking(e);
    }
  }, []);

  if (isGdds) {
    return (
      <Link
        stylingType={stylingType || 'secondary'}
        text={renderLinkContent(children, content)}
        target={content.target}
        onClick={handleTracking}
        hasArrow={hasArrow}
        alignByContent={alignByContent}
        className={className}
        windowTarget="_blank"
        standardFontSize={standardFontSize}
      />
    );
  }

  return (
    <a
      href={content.target}
      className={className}
      onClick={handleTracking}
      aria-label={content.text || content.target || undefined}
      target="_blank"
      rel="noreferrer"
    >
      {renderLinkContent(children, content)}
    </a>
  );
}
