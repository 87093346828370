import { trackTemplate } from '../../utils/tracking';

/**
 * Download link events for various file types
 * @param string type
 * @param string link
 */
function downloadLink(type, link) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Downloads',
      action: type,
      label: link,
      value: '0',
    },
  });
}

/**
 * Generic link events
 * @param string type
 * @param string link
 */
export function genericLink(type, link) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Link',
      action: type,
      label: link,
      value: '0',
    },
  });
}

/**
 * Social links
 * @param string link
 * @param string name Social Sharing action name
 * @param string target Social sharing icon as target
 * @param string category Social sharing category, default is Clicks
 */
function socialLink(link, name, target, category) {
  if (name === 'Social-Icons') {
    return trackTemplate({
      event: 'genericEvent',
      data: {
        category: 'Footer',
        action: 'Social-Icons',
        label: target,
        value: '0',
      },
    });
  }
  const targetString = target ? `${target} - ` : '';
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: category || 'Clicks',
      action: name,
      label: `${targetString}${link}`,
      value: '0',
    },
  });
}

/**
 * Filesharing links
 * @param string link
 * @param string text
 */
function fileshareLink(link, text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Fileshare',
      action: text,
      label: link,
      value: '0',
    },
  });
}

/**
 * External link entry, to match according to
 * different link scenarios.
 * @param string link
 * @param string text
 * @param string socialName
 * @param string socialIcon
 * @param string category
 */
export function externalLink(link, text, socialName, socialIcon, category, localMedium) {
  if (localMedium) {
    return internalMediumLink(link);
  }

  if (!socialName) {
    if (link.includes('catalogs')) {
      return genericLink('Links to Geberit Catalogs', link);
    }
    if (/^https?:\/\/(www\.)?geberit-aquaclean\./g.test(link)) {
      return genericLink('Links to AquaClean-Domains', link);
    }
    if (/^https?:\/\/(www\.)?geberit\./g.test(link)) {
      return genericLink('Links to Geberit Domains', link);
    }
  }

  if (
    new RegExp(
      [
        'facebook',
        'twitter',
        'instagram',
        'mailto',
        'youtube',
        'linkedin',
        'pinterest',
        'xing',
      ].join('|'),
    ).test(link) &&
    socialName
  ) {
    return socialLink(link, socialName, socialIcon, category);
  }
  if (link.includes('fileshare')) {
    return fileshareLink(link, text);
  }

  return genericLink('Outbound Link', link);
}

/**
 * Internal Medium links
 * @param string link
 */
export function internalMediumLink(link) {
  if (link.includes('.pdf')) {
    return downloadLink('PDF', link);
  }
  if (new RegExp(['.xls', '.xlsx', '.csv'].join('|')).test(link)) {
    return downloadLink('Excel', link);
  }
  if (new RegExp(['.doc', '.docx'].join('|')).test(link)) {
    return downloadLink('Word', link);
  }
  if (new RegExp(['.ppt', '.pptx'].join('|')).test(link)) {
    return downloadLink('PowerPoint', link);
  }
  if (link.includes('.zip')) {
    return downloadLink('ZIP', link);
  }
  if (
    new RegExp(
      ['.dwg', '.rvt', '.rfa', '.stp', '.dxf', '.igs', '.sat', '.vdi', '.tga'].join('|'),
    ).test(link)
  ) {
    return downloadLink('CAD', link);
  }
  if (new RegExp(['.mp4', '.avi', '.mpg', '.wmv', '.mov'].join('|')).test(link)) {
    return downloadLink('Video-Download', link);
  }
  if (
    new RegExp(['.001', '.d81', '.rtf', '.xml', '.json', '.exe', '.msi', '.dmg'].join('|')).test(
      link,
    )
  ) {
    return downloadLink('Miscellaneous', link);
  }

  return downloadLink('Other', link);
}

/**
 * Mail links
 * @param string link
 * @param string name
 * @param string socialIcon
 * @param string category
 */
export function mailLink(link, name, socialIcon, category) {
  if (socialIcon && name) {
    return socialLink(link, name, socialIcon, category);
  }

  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Link',
      action: 'EMail-Address Link',
      label: link,
      value: '0',
    },
  });
}

/**
 * Phone links
 * @param string link
 */
export function phoneLink(link) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Link',
      action: 'Phone Link',
      label: link,
      value: '0',
    },
  });
}

/**
 * Internal links
 * @param string link
 */
export function internalLink(link) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Link',
      action: 'Internal Link',
      label: link,
      value: '0',
    },
  });
}

/**
 * PopUp links
 * @param string link
 */
export function popUpLink(link) {
  const isInternal =
    String(link).includes(window.location.hostname) || !String(link).includes('http');
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Link',
      action: isInternal ? 'Internal Link' : 'Outbound Link',
      label: link,
      value: '0',
    },
  });
}

export function internalFooterLink(action, label) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Footer',
      action,
      label,
      value: '0',
    },
  });
}
